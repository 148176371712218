* {
  font-family: "Poppins", sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
  /* font-family: "Playfair Display", serif; */
}
button {
  font-family: "Playfair Display", serif !important;
}
.navbar-collapse::-webkit-scrollbar,
.restaurant-requests-wrapper::-webkit-scrollbar,
.form-input-wrapper::-webkit-scrollbar,
.restaurant-request-notify::-webkit-scrollbar {
  display: none;
}
.terms-list-wrapper::-webkit-scrollbar,
.category-container::-webkit-scrollbar {
  width: 5px;
}
.category-container::-webkit-scrollbar {
  height: 5px;
}

.terms-list-wrapper::-webkit-scrollbar-thumb,
.category-container::-webkit-scrollbar-thumb {
  background-color: #442b7e;
  border-radius: 6px;
}
.terms-list-wrapper::-webkit-scrollbar-track,
.category-container::-webkit-scrollbar-track {
  background-color: rgb(171, 171, 171);
  border-radius: 6px;
}
.res-btn button {
  border: 1px solid !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #ddd !important;
}
.privacy-sub-list {
  list-style: upper-roman !important;
}

/* the to hide the left side of the rect-date-range */
/* .rdrDefinedRangesWrapper {
    display: none;
} */

.input-placeholder > div > input::placeholder {
  color: #101012 !important;
  text-transform: capitalize;
  font-weight: 500;
}

span.__rdt_custom_sort_icon__.asc svg {
  transform: rotate(0) !important;
}

select {
  font-family: "Poppins", sans-serif !important;
}

.navbar-ul-list::-webkit-scrollbar {
  width: 5px;
}

.navbar-ul-list::-webkit-scrollbar-thumb {
  background-color: #4d4c4c;
  border-radius: 6px;
}

.navbar-ul-list::-webkit-scrollbar-track {
  background-color: #afadad;
  border-radius: 6px;
}

::backdrop {
  background-color: white;
  z-index: 9;
}
